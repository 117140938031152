<template>
  <div>
    <ca-alert v-if="showError" type="error">{{ t("url_is_invalid") }}</ca-alert>

    <base-card v-if="!showError">
      <v-card-text>
        <div>
          {{ t("confirming_purse") }}
        </div>
      </v-card-text>
    </base-card>
  </div>
</template>

<script>
import CaAlert from "@/views/components/Alert";

export default {
  components: { CaAlert },
  metaInfo() {
    return {
      title: this.$t("titles.purse_confirmation")
    };
  },
  data() {
    return {
      showError: false
    };
  },
  mounted() {
    this.$store
      .dispatch("payouts/confirmPurse", {
        code: this.$route.params.code
      })
      .then(() => {
        this.$store.dispatch("app/showMainAlert", {
          message: this.t("purse_is_confirmed"),
          dismissible: true
        });

        this.redirectToPayouts();
      })
      .catch(() => {
        this.showError = true;
      });
  },
  methods: {
    redirectToPayouts() {
      this.$router.push({ name: "payouts" });
    },
    t(key, params) {
      return this.$t("pages.payouts." + key, params);
    }
  }
};
</script>
